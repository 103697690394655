import { AxiosRequestConfig } from "axios";
import { stringify } from "qs";

/*  This file configures Axios to send requests with the specified headers and parameters serialization format for URL-encoded form data */
export const axiosRequestConfiguration: AxiosRequestConfig = {
    
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },

    paramsSerializer: {
        /* The serialize function takes an object of key-value pairs as input and uses the qs.stringify method to convert it into a URL-encoded string.
        The arrayFormat: "repeat" option specifies how arrays should be serialized in the URL parameters. */
        serialize: (params: Record<string, number>) => {
            return stringify(params, { arrayFormat: "repeat" });
        },
    },
};
