<template>
    <div id="page_style" class="rows site-content page__style page__description" autocomplete="off">
         
        <!-- Search input section -->
        <div class="container-fluid banner mz-5">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
        </div>

        <!-- Results area on top of the list of publications -->
        <div class="columns">
            <!-- Left sidebar section. Empty, just to keep simetry -->
            <div id="id-side-bar" class="column is-4 sidebar_column" style="padding-top: 0rem; padding-right: 1.5rem; padding-left: 1.5rem">
            </div>

            <!-- Results section -->
            <div class="col col-8 column is-8 results_column" style="padding-top: 0.5rem; padding-right: 1rem; padding-left: 1rem; padding-bottom: 0rem;">
                <!-- Display results if any -->
                <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <span class="font-medium pl-5">Your search term</span> <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>{{ numFound }}</strong> results:
                    </div>
                </div>
                <!-- Display message if no results found -->
                <div v-else-if="results.length == 0">
                    <div class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <!-- <span class="font-medium pl-5">Your search yielded <strong> 0</strong> results.</span> -->
                        <span class="font-medium pl-5">Your search term</span> <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>0</strong> results:
                    </div>
                </div>
            </div>
        </div>

        <!-- Area with the list of facets (left) and list of publications (right) -->
        <div class="columns">
            <!-- Sidebar with facets -->
            <div id="id-side-bar" class="column is-4 sidebar_column" style="padding-top: 0.5rem; padding-right: 1.5rem; padding-left: 1.5rem">
                <div id="externals" class="">
                    <div v-for="(facetItems, key, index) in facets" v-bind:key="index" name="external_card" style="margin-bottom: 0px">
                        <facet-category v-bind:facetItems="facetItems" v-bind:filterName="key" @filter="onFilter"></facet-category>
                    </div>
                </div>
            </div>

            <!-- Main results section with pagination and active filters -->
            <div class="col col-8 column is-8 results_column" style="padding-top: 0.5rem; padding-right: 1rem; padding-left: 1rem">
                <div v-if="activeFilterCategories && Object.keys(activeFilterCategories).length > 0" class="column">
                    <span v-for="(values, key, index) in activeFilterCategories" v-bind:key="index" class="active-filter-items">
                        <!-- Active filter categories -->
                        <active-facet-category
                            v-bind:filterItems="values"
                            v-bind:categoryName="key"
                            @clear-facet-category="onClearFacetCategory"
                        ></active-facet-category>
                    </span>
                </div>
                <div class="results">
                    <!-- Pagination before search results -->
                    <PaginationComponent class="mb-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                    <!-- Results section -->
                    <vs-result v-bind:datasets="results"></vs-result>
                    <!-- Pagination after search results -->
                    <PaginationComponent class="mt-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                </div>
            </div>
        </div>

        <!-- Partner logos section -->
        <div class="container-fluid">
            <!-- <div class="columns is-mobile partner-logos"> -->
            <div class="columns">
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <!-- <h5 class="card-title">About TETHYS</h5> -->
                            <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                                <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 28rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="http://www.geosphere.at/">
                                <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                                <img src="@/assets/site/img/base_logo.png" alt="logo base" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import SearchViewComponent from "./search-view-component";
export default SearchViewComponent;
// import tailwind ss
import "@/index.css";
</script>

<style scoped></style>