import axios, { AxiosRequestConfig, AxiosInstance } from "axios";

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
    
    delete axios.defaults.headers.common["X-Requested-With"];

    const axiosInstance = axios.create(config);

    return axiosInstance;
};

export default initialization;