<!-- Contains the template and references the TypeScript logic. -->
<template>
  <div id="map" style="height: 300px;"></div>
</template>

<script lang="ts">
import Minimap from './Minimap';
export default Minimap;
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}
</style>