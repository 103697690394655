declare const APP_URL: string;
declare const VUE_API: string;
// declare const SOLR_HOST: string;
// declare const SOLR_CORE: string;

// OPENSEARCH
declare const OPEN_HOST: string;
declare const OPEN_CORE: string;

const _APP_URL = APP_URL;
const _VUE_API = VUE_API;
// const _SOLR_HOST = SOLR_HOST;
// const _SOLR_CORE = SOLR_CORE;

// OPENSEARCH
const _OPEN_HOST = OPEN_HOST;
const _OPEN_CORE = OPEN_CORE;

export { _APP_URL as APP_URL };
export { _VUE_API as VUE_API };
// export { _SOLR_HOST as SOLR_HOST };
// export { _SOLR_CORE as SOLR_CORE };

// OPENSEARCH
export { _OPEN_HOST as OPEN_HOST };
export { _OPEN_CORE as OPEN_CORE };
