import { Dataset } from "./dataset";

export interface SolrResponse {
    responseHeader: ResponseHeader;
    response: ResponseContent;
    facets: FacetFields;
    // facet_counts: FacetCount;
}

export interface ResponseHeader {
    status: boolean;
    QTime: number;
    params: ResponseHeaderParams;
}

export interface ResponseHeaderParams {
    defType: string;
    rows?: number;
    start?: number;
    wt?: string;
}

export interface ResponseContent {
    numFound: number;
    start: number;
    docs: Array<Dataset>;
}

//Used
export class FacetResults {
    [key: string]: Array<FacetItem>;
}

export class FacetFields {
    language!: FacetInstance;
    subject!: FacetInstance;
}

export interface FacetInstance {
    [key: string]: Array<FacetItem>;
}

//Used
export class FacetItem {
    val: string;
    count: number;
    category: string;
    active: boolean;

    constructor(value: string, count: number) {
        this.val = value;
        this.count = count;
        this.active = false;
        this.category = "";
    }
}
//#endregion

// OPENSEARCH
// ========================================================================
export interface OpenSearchResponse {
    took: number;
    timed_out: boolean;
    _shards: Shards;
    hits: Hits; // Equivalent SOLR: response > docs
    aggregations?: Aggregations; // Equivalent SOLR: facets
}

export interface Shards {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
}

export interface Hits {
    total: Total;
    max_score: number;
    hits: Array<Hit>;
}

export interface Total {
    value: number; // Equivalent SOLR: response > numFound
    relation: string;
}

export interface Hit {
    _index: string;
    _id: string;
    _score: number;
    _source: Dataset;
    highlight: HitHighlight; // !! This name is to avoid collision with Typescript "Highlight" class
}

export interface HitHighlight {
    subjects?: Array<string>;
    title?: Array<string>;
    author?: Array<string>;
    doctype?: Array<string>;
}

export interface Aggregations { // Equivalent SOLR: FacetFields
    subjects: Subjects;
    language: Language;
    doctype: Doctype;
}

export interface Subjects {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: Array<Bucket>;
}

export interface Language {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: Array<Bucket>;
}

export interface Doctype {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: Array<Bucket>;
}

export interface Bucket {
    key: string;
    doc_count: number;
}
// // Needed?
// export interface Aggregations {
//     [key: string]: Aggregation;
// }

// export interface Aggregation {
//     buckets: Array<Bucket>;
// }


