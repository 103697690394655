import { Component, Vue, Prop, Emit } from "vue-facing-decorator";
// import { Prop, Emit } from "vue-property-decorator";

@Component({
    name: "ActiveFacetCategory",
})
export default class ActiveFacetCategory extends Vue {

    @Prop({
        type: Array<string>,
    })
    filterItems!: string[];

    @Prop({
        type: String,
        required: true,
    })
    categoryName!: string;


    replacements = new Map<string, string>([
        ["gis", "GIS"],
        ["analysisdata", "Analysis Data"],
        ["models", "Models"],
        ["monitoring", "Monitoring"],
        ["measurementdata", "Measurement Data"],
        ["mixedtype", "Mixed Type"],
        ["de", "Deutsch"],
        ["en", "English"]
    ]);

    // @Prop([String])
    // alias;

    /**
     * The alias for the Active facet box will be set depending on the name of the category. 
     * This will allow to display the customised terms "creator" and "keyword" instead of the values currently used in the OpenSearch index: "author" and "subjects"
     * TODO: This should be corrected directly in the index
     */
    get categoryAlias(): string {
        // return this.categoryName == "doctype" ? "datatype" : this.categoryName;
        // console.log("getAlias!");
        switch (this.categoryName) {
            case "author":
                return "creator";
            case "subjects":
                return "keyword";
            case "doctype":
                return "data type";
            default:
                return this.categoryName;
        }
    }

    /**
     * The alias for the items inside the "doctype / Datatype" category will be set manually in order to show user-friendly terms instead of the predefined doctypes in the DB
     * If the category alias is Data Type, the name of the items is set
     * NOTE: This could be corrected directly in the index
     */
    getFilterItemsAlias(categoryAlias: string): string {
        console.log(categoryAlias);
        if (categoryAlias === ("data type") || categoryAlias === ("language")) {
            /**
             * Iterate over the filterItems array using the map method to create a new array (updatedItems).
             * For each item in the array, check if the item exists as a key in the replacements map.
             * - If the item exists in the replacements map, replace it with the corresponding value from the map.
             * - If the item does not exist in the replacements map, keep the original item unchanged.
             * The map method returns a new array where each element is either the original item or its replacement.
             *  */ 
            const updatedItems = this.filterItems.map((item) =>
                this.replacements.get(item) || item
            );

            return updatedItems.join(" | ");
        }

        // console.log("other categories");
        return this.filterItems.join(" | ");
    }

    // get filterItems(): Array<string> {
    //     return this.data;
    // }

    // get uncollapseLabelText() : string {
    //     if (this.collapsed == true) {
    //         // return myLabels.viewer.sidePanel.more; //"More results";
    //         return "More results";
    //     }
    //     else {
    //         // return myLabels.viewer.sidePanel.collapse; //"Collapse";
    //         return "Collapse";
    //     }
    // }

    // toggle = function (): void {
    //     if (this.collapsed == true) {
    //         this.collapsed = false;
    //     }
    //     else if (this.collapsed == false) {
    //         this.collapsed = true;
    //         //list.children("li:gt(4)").hide();
    //     }
    // }

    @Emit("clear-facet-category")
    deactivateFacetCategory(): string {
        // filterItem.Category = this.alias;
        // filterItem.Active = true;
        // this.$emit("clearFacetCategory", this.categoryName, this.alias);
        return this.categoryName;
    }
}
