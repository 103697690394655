// Import the necessary modules and functions
import initializeAxios from "./axiosSetup"; // Function to initialize the Axios instance
import { axiosRequestConfiguration } from "./config"; // Axios configuration settings
import { map } from "rxjs/operators"; // Operator to transform the items emitted by an Observable
import { defer, Observable } from "rxjs"; // RxJS utilities for creating and working with Observables
import { AxiosResponse } from "axios"; // Axios response type

// Initialize the Axios instance with the provided configuration
const axiosInstance = initializeAxios(axiosRequestConfiguration);

// Function to make a GET request using Axios wrapped in an Observable
// eslint-disable-next-line
const get = <T>(url: string, queryParams?: any): Observable<T> => {
    // Use defer to create an Observable that makes the Axios GET request when subscribed to
    return defer(() => axiosInstance.get<T>(url, { params: queryParams }))
        // Use map to transform the Axios response to extract the data property
        .pipe(map((result: AxiosResponse) => result.data));
};

// Function to make a POST request using Axios wrapped in an Observable
const post = <T>(url: string, body: any, queryParams?: any): Observable<T> => {
    // Use defer to create an Observable that makes the Axios POST request when subscribed to
    // console.log(body);
    // console.log(queryParams);
    
    return defer(() => axiosInstance.post<T>(url, body, {
        params: queryParams,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }))
    .pipe(map((result: AxiosResponse) => result.data)); // Use map to transform the Axios response to extract the data property
};

// Export the get and post functions as part of the default export
export default { get, post };